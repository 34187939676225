.fav-masjid-container {
    .mosques {
        padding-right: 5px;
        padding-left: 5px;
        .mosque-details, .fav-masjid-loader{
            width: 600px;
            max-width: 100%;
            margin: 20px auto;
        }
    }
}

.fav-masjid-container-fluid{
    margin-top: 0;
}