#editPopup .modal-content{
    width: 350px !important;
    margin: auto;
}


.mobile_time_picker{
    width: 100%;

    &.juma_time {
        & label{
            color: #e30a17;
        }
    }

}