.pagination{
    padding: 5px 20px;
    z-index: 2;
    background: white;
}


.iconButton{
    padding: 0 3px;
    border: 0 solid rgb(255, 255, 255);
    background: transparent;
}