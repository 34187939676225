
header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 2rem;
    margin-top: 80px;
}
header h1 {
    font-size: 2.5rem;
    margin: 0;
}

/* Styling for the main content */
.container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 10px;
}
.container h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
}
.container p {
    line-height: 1.6;
}
