.search-button {
  padding: 8px 30px 8px 45px;
  color: #3A3A38;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background: url(../../components/Masjid/MasjidCard/CardAssets/images/detect.png) #E8D0E5 no-repeat;
  background-size: 20px;
  background-position: left 8px center;

  @media (max-width: 991px) {
    height: 50px;
    background-position: center;
  }

  span {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.search-button:hover {
  background-color: rgba(43, 30, 71, 0.8);
  color: #fff;
}