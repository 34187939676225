/* Avatar image */
img.avatar {
    width: 40px;
    lenght: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .login-form {
    padding: 50px 0;
    max-width: 500px;
    margin: auto;
  }
    h1 {
    font-size: 25px;
    text-align: center;
    color: black;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: normal;
    font-family: 'Josefin Sans', sans-serif;
  }
  
  .social-icon {
    width: 100%;
    font-size: 20px;
    padding-top: 20px;
    color: #fff;
    text-align: center;
    float: left;
  }
  
  .logo h3 {
    font-size: 20px;
    margin: 0px 0px 9.6px;
    padding-top: 40px;
    font-weight: bold;
    color: black;
    line-height: 2;
    font-family: 'Montserrat', sans-serif;
  }
  .form-group {
    float: left;
    width: 100%;
    margin: 0 0 15px;
    position: relative;
  }
  
 
  
    .login-btn {
    background: #00401a;
    padding: 11px 50px;
    border-color: #00401a;
    color: #fff;
    text-align: center;
    margin: 20px auto 0;
    font-size: 20px;
    border: 1px solid #00401a;
    border-radius: 44px;
    width: 80%;
    height: 40px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .reset-psw {
    float: left;
    width: 100%;
    text-decoration: none;
    color: grey;
    font-size: 14px;
    text-align: center;
    margin-top: 11px;
  }
  
  .social-icon button {
    font-size: 20px;
    color: white;
    height: 50px;
    width: 50px;
    background: #1017e3;
    border-radius: 60%;
    margin: 10px 10px;
    border: none;
    cursor: pointer;
  }
  
  
  button:hover {
    opacity: 0.9;
  }
  
 .seperator {
    float: left;
    width: 100%;
    border-top: 1px solid #ccc;
    text-align: center;
    margin: 50px 0 0;
  }
  
 .seperator b {
    width: 40px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    background: #fff;
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: relative;
    top: -22px;
    z-index: 1;
  }
  
  p {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 0 0 10px;
    color: grey;
  }

  .main-column__inner {
    padding-left: 20px;
    padding-right: 20px;
    margin: 40px auto;
    max-width: 750px;
    position: relative;
}
.column-content {
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    margin-bottom: 20px;
    height: 660px;
}
  
.column-content__title {
    font-family: CircularPro, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 18px !important;
    line-height: 22px !important;
    margin: 0 !important;
    padding: 0;
}
.column-content__subtitle {
    color: #555;
    display: block;
    font-family: Proxima Nova, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    margin: 2px 0 50px 0 !important;
}
label {
    color: #333;
    font-family: CircularPro, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .03em;
    line-height: 15px;
    margin: 0 0 8px;
}

.form-control {
    background: #fff;
    border: 1px solid #9e9e9e;
    border-radius: 0.75rem;
    color: #0c0c0c;
    display: block;
    font-family: Brandon Grotesque Regular, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: .875rem;
    font-style: normal;
    font-weight: 390;
    height: 3.25rem;
    line-height: 1.5rem;
    padding: 0.5rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}
.form-group {
    position: relative;
}
.form-group {
    margin-bottom: 1.2rem;
}
.trf-control-label-required {
    color: #fa5e5b;
    font-size: 12px;
}
.small, small {
    font-size: 80%;
    font-weight: 400;
}
.input-icon{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
  @media screen and (max-width:767px) {
    .login-form {
        width: 90%;
        padding: 15px 15px 30px;
    }
    .social-icon
    {
        padding: 20px;
        padding-bottom: 10px;
  
  
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* RegistrationForm.css
  
  .registration-form {
      min-width: 400px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .outer_div{
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 60px);
    }
    
    .registration-form h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
    
    .registration-form form {
      display: flex;
      flex-direction: column;
    }
    
    .registration-form input {
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 1rem;
    }
    
    .registration-form button {
      padding: 10px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: 1rem;
    }
    
    .registration-form button:hover {
      background-color: #0056b3;
    }
     */