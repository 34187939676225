/* RegistrationForm.css */

.registration-form {
    min-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 80px);
    overflow: scroll;
  }
  
  .outer_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
  }
  
  .registration-form h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .registration-form form {
    display: flex;
    flex-direction: column;
  }
  
  .registration-form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
  }
  
  .registration-form button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .registration-form button:hover {
    background-color: #0056b3;
  }
  