.mosque-card-top {
    display: flex;
    position: relative;

}

.mosque-img img {
    width: 150px;
    border-radius: 10px;
}

.mosque-name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 600;
    color: gray;
    justify-content: space-between;
    width: 80%;

    @media screen and (max-width: 499px) {
       width: 50%;
    }


}

.mosque-name a {
    background-color: hsl(160, 83%, 26%);
    font-size: 10px;
    border-radius: 10px;
    padding: 8px;
    color: #ffffff;
    font-weight: bold;
    margin-top: -5px;
    position: absolute;
    left: 90%;
    top: 5%;

    text-decoration: none;



}

.mosque-name a:hover {
    background-color: hsl(0, 0%, 92%);
    color: #0b7955;
    text-decoration: none;
    border: 1px solid hsl(160, 83%, 26%);
}

.mosque-name img {
    width: 30px;
    margin-right: 10px;
}

.mosque-details {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    min-height: 1px;
    width: 100%;
    border: 0;
    margin: 5px 0 15px 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.mosque-details.grey-bg:hover {
    background: #dcffe5;
}

.mosque-details.red-bg:hover {
    background: #f8d7da;
}

.mosque-details.white-bg:hover {
    background: #fff;
}

.mosque-details.yellow-bg:hover {
    background: #fff3cd;
}

.mosque-details.blue-bg:hover {
    background: #cfe2ff;

}

.mosque-details.white-bg:hover {
    background: #C7F3F3;

}

.adress .adress-pointer {
    color: #333;
    font-style: italic;
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
}

.adress,
.distance {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.adress i,
.distance i {
    margin-right: 10px;
}


.prayer-times-container {
    display: flex;
    justify-content: center;
    color: #333;
    gap: 5px;
    margin-top: 15px;
    flex-wrap: wrap;
}

.prayer-times-container .prayer-label {
    font-size: 15px;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff
}

.prayer-times-container .prayer-time {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    color: #ffffff;
    line-height: 1.1;
}

.prayer-times-container .prayer-time-item {
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    flex: 1;
    color: gray;
    cursor: pointer;
    transition: all 0.3s ease;
    @media only screen and (max-width: 499px) {
        flex: 1 0 calc(33.333% - 5px)!important; /* Adjust flex basis for smaller screens */
    }
}

.prayer-times-container .prayer-time-item .namaz-icon {
    margin-bottom: 10px;
}

.smaller {
    &.mosque-details{
        box-shadow: none;
        margin: 0;
        padding: 0;
        &:hover{
            background-color: transparent;
        }
    }
    .masjid-button-conatiner {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .prayer-times-container {
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .prayer-times-container .prayer-time-item {
        flex: 0 0 calc(33.33% - 10px);
        /* 33.33% width for each item with gap included */
    }
}

.add_masjid {
    position: absolute;
    left: 90%;
    bottom: 5%;
    z-index: 99999999;
}

.direction-button{
    color:#00401A;
    &:hover{
        color:#E30A17
    }
}

@media screen and (max-width: 990px) {
    .map_container {
        position: absolute;
        top: 160px;
        height: calc(100vh - 198px);
        overflow: hidden;
    }

    .pagination {
        position: fixed;
        bottom: 0;
    }
}

@media screen and (max-width: 499px) {
    .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 50px;
        overflow: hidden;
        width: 100%;
    }

    .masjid-button-conatiner{
        position: absolute;
        top: 0;
        right: 0;
    }

    .mosque-img img {
        height: 280px;
        object-fit: cover;
    }

    .mosque-name {
        font-size: 20px !important;
    }

    .mosque-adress a {
        margin-top: 10px;
    }

    #modalPopup .login-form {
        width: auto !important;
    }

}