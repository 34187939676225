.container {
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container p {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
}

.container a.nav-link {
    color: #007bff; /* Blue color for the link */
    text-decoration: none; /* Remove underline */
}

.container a.nav-link:hover {
    text-decoration: underline; /* Underline on hover */
}

.login-form.resetPassword{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
