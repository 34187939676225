.view-btn::before {
    display: block;
    position: absolute;
    top:0;
    left: 10px;
    z-index: -1;
    width: 5.3em;
    height: 5.3em;
    border-radius: 50%;
    background: black;
    opacity: 0;
    content: '';
    animation: pulse 1s infinite;
  }

  .btn:hover::before {
    animation: none;
    opacity: .2;
    transform: scale(1.2);
  }

  @keyframes pulse {
    from {
      transform: scale(1);
      opacity: .4;
    }
    to {
      transform: scale(1.2);
      opacity: 0;
    }
  }
@media screen and (max-width: 990px) {

    .mapViewBtn {
        z-index: 1;
        width: 100%;
        // padding: 0;
        position: absolute;
        bottom: 100px;
        text-align: right;
        right: 3rem;
        width: 6em;
    }
    .mapViewBtn .view-btn {
        width: 5em;
        height: 5em;
        border-radius: 50%;
        background-color: #00401A;
        display: inline-block !important;
    }
}
@media screen and (max-width: 437px) {
    .view-btn{
        width: 4rem !important;
        height: 4rem !important;
    }

    .view-btn::before {
        width: 4.3em;
        height: 4.3em;
        top: -2px;
        left: 17px;
    }

    .mapViewBtn{
        right: 1rem;
    }
}

@media screen and (min-width: 990px) {
    .mapViewBtn {
        display: none;
    }
}