* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}

/* .slick-and-login .login-section .container-fluid{
  margin-top: 0;
} */

.google-login-button {
  /* Your custom styles here */
  background-color: #4285f4;
  color: white;
  border: 1px solid red;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.carousel-item img {

  height: 100%;
  object-fit: cover;
}

.slider-colomn {
  height: 100vh;
  padding: 50px 0;
}
@media screen and (max-width: 767px) {
  .sticky-top {
      position: relative !important;
  }

  .slider-colomn {
      display: none;
  }
}
.slider-colomn .carousel,
.slider-colomn .carousel-inner,
.slider-colomn .carousel-item,
.slider-colomn .carousel-item img {
  height: 100%;
}.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
  width: 40px;
  lenght: 40px;
  height: 40px;
  border-radius: 50%;
}

@media screen and (min-width:992px){
  .slick-and-login .login-section .container-fluid{
    margin-top: 0;
  }
}

@media screen and (max-width:767px) {
  .social-icon
  {
      padding: 20px;
      padding-bottom: 10px;


  }
}














/* RegistrationForm.css

.registration-form {
    min-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .outer_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
  }
  
  .registration-form h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .registration-form form {
    display: flex;
    flex-direction: column;
  }
  
  .registration-form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
  }
  
  .registration-form button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .registration-form button:hover {
    background-color: #0056b3;
  }
   */