@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600&family=Montserrat:wght@100&display=swap');

.carousel-item img {

    height: 100%;
    object-fit: cover;
}

.slider-colomn {
    height: 100vh !important;
    padding: 50px 15px !important;
}
@media screen and (max-width: 767px) {
    .sticky-top {
        position: relative !important;
    }

    .slider-colomn {
        display: none;
    }
}
.slider-colomn .carousel,
.slider-colomn .carousel-inner,
.slider-colomn .carousel-item,
.slider-colomn .carousel-item img {
    height: 100%;
}.imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
    width: 40px;
    lenght: 40px;
    height: 40px;
    border-radius: 50%;
}