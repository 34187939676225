.header-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  nav {
    background: #003f22;
    height: 80px;
    width: 100%;
    display: flex;
    /* Add this line to use Flexbox */
    justify-content: space-between;
    /* Align items horizontally */
    align-items: center;
    /* Center items vertically */
  }

  label.logo {
    color: white;
    font-size: 25px;
    line-height: 80px;
    padding: 0 35px;
    font-weight: bold;
  }

  nav ul {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    align-items: center;

    @media (max-width: 991px) {
      display: block;
    }
  }

  nav ul li {
    display: inline-block;
    line-height: 80px;
  }

  nav ul li .nav-link {
    color: white;
    font-size: 17px;
    padding: 0 13px;
    /* border-radius: 3px; */
    text-transform: uppercase;
  }

  .nav-link.active,
  .nav-link:hover {
    background: #516c5c;
    transition: .5s;
    color: white;
  }

  .nav-link.signIn {
    background: #E30A17;
  }

  .checkbtn {
    font-size: 30px;
    color: white;
    /* float: right; */
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
  }

  #check {
    display: none;
  }

  .dropbtn {
    background-color: #E30A17;
    color: white;
    padding: 0 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    min-width: 140px;
  }

  .mobile-item {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin: 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
  }

  .dropdown-content a {
    color: black;
    padding: 0 16px;
    text-decoration: none;
    display: block;
    line-height: 50px;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

  @media (max-width: 991px) {
    label.logo {
      font-size: 25px;
      padding-left: 25px;
    }

    nav ul li .nav-link {
      font-size: 16px;
    }
  }

  @media (max-width: 991px) {
    label.logo {
      font-size: 20px;
      padding-left: 25px;
    }

    .checkbtn {
      display: block;
    }

    ul {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #516c5c;
      top: 80px;
      left: -100%;
      text-align: center;
      transition: all .5s;
      z-index: 999999999999;
      padding-left: 0;
    }

    nav ul li {
      display: block;
      margin: 35px 0;
      line-height: 30px;
      text-align: left;
    }

    nav ul li .nav-link {
      font-size: 20px;
    }

    .nav-link:hover,
    .nav-link.active {
      background: none;
      color: #000000;
    }

    #check:checked~ul {
      left: 0;
    }
  }

  @media (max-width: 320px) {
    label.logo {
      font-size: 20px;
      padding-left: 25px;
      padding-right: 0;
    }

    nav ul li .nav-link {
      font-size: 16px;
    }
  }

  .dropbtn{
    &:hover .icon {
      animation: iconRotate 0.5s ease forwards;
    }
  }

  .icon {
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  @keyframes iconRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(180deg); // Adjust the value to change the amount of rotation
    }
  }
}
// .container-fluid {
//   margin-top: 80px;
// }