*
{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
body, a, p, span, strong, div {
    font-family: 'Roboto Mono', monospace;
}
body{
    overflow: auto;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Josefin Sans', sans-serif;
}

.container-fluid.mosque_card_container{
    margin-top: 80px !important;
}
#modalPopup .login-form{
    width: 400px !important;
}

.search-container {
    background-color: #f1f1f1;
    border-radius: 50px;
    overflow: hidden;
    margin-top: 15px;
}
 

 .drop-down
 {
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .drop-down h3
 {
   
        font-size: 18px;
        margin: 20px 0;

 }
 .dropdown {
   margin: 28px;
    }
#secondary-btn{
    background-color: #E8D0E5;
     border-radius: 50px;
     border: none;
     color: #3A3A38;
}
#secondary-btn:hover
{
    background-color: rgba(43, 30, 71, 0.8);
    color: #fff;
}

.find-masjid-mosque{
    &.mosques {
        height: calc(100vh - 200px);
        overflow-y: auto;
        padding-right: 5px;
        padding-left: 5px;
        margin-top: 15px;
    }
}

.mosques::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

.mosques::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}

.mosques::-webkit-scrollbar-thumb {
    background-color: #E8D0E5;
}
@media screen and (max-width: 990px) {
    .find-masjid-mosque{
        &.mosques {
            height: calc(100vh - 175px);
        }
    }
}

